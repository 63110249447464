.image {
    min-height: 100vh;
    position: relative;
  }
  
  .image .content {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
  }